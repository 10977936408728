import api from "./index";

export default {
  async create(reminder) {
    return api.post("/account/api/reminders", { reminder });
  },
  async index() {
    try {
      return await api.get("/account/api/reminders");
    } catch (error) {
      return false;
    }
  },
  async delete(id) {
    try {
      return await api.delete(`/account/api/reminders/${id}`);
    } catch (error) {
      return false;
    }
  },
  async update(id, params) {
    return api.put(`/account/api/reminders/${id}`, params);
  }
};
