/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import addresses from "../../api/addresses";
import savedDesign from "../../api/savedDesign";
import cartItems from "../../api/cartItems";
import reminders from "../../api/reminders";

const state = {
  cardModalShowing: false,
  sendToThemModalShowing: false,
  askForDispatchDate: true,
  askForAddress: true,
  postcardAddressId: null,
  updatingFromCart: false,
  indirectSendAvailable: true,
  contactAddresses: [],
  remindable: false,
  hasSeenReminderModal: false,
  reminderModalShowing: false,
  sendToThemData: null,
  quickAddIds: []
};

const actions = {
  async createReminder({ commit }, { date, name }) {
    commit("setLoading", true, { root: true });
    return reminders.create({
      reminder_type: "general",
      notify: true,
      date,
      name
    });
  },
  async sendToMe({ commit, getters }, designId) {
    if (getters.canShowReminderModal) {
      commit("setLoading", false, { root: true }, designId);
      commit("setCardModalShowing", false);
      commit("setReminderModalShowing", true);
    } else {
      commit("setLoading", true, { root: true }, designId);
      const response = await savedDesign.addCardToCart(designId, {
        send_to_me: true,
        quick_add_ids: state.quickAddIds
      });
      if (response) {
        if (response.data.success) {
          window.location = response.data.path;
        }
      } else {
        // Handle error
      }
    }
  },
  async sendToThem({ commit, getters }, { designId, addressId, deliveryDate }) {
    if (getters.canShowReminderModal) {
      commit("setLoading", false, { root: true }, designId);
      commit("setCardModalShowing", false);
      commit("setReminderModalShowing", true);
      commit("setSendToThemData", { addressId, deliveryDate });
    } else {
      commit("setLoading", true, { root: true });
      const response = await savedDesign.addCardToCart(designId, {
        send_to_me: false,
        address_id: addressId,
        delivery_date: deliveryDate,
        quick_add_ids: state.quickAddIds
      });
      if (response) {
        window.location = response.data.path;
      } else {
        // Handle error
      }
    }
  },
  async createAddress({ commit }, address) {
    commit("setLoading", true, { root: true });
    try {
      const addressParameters = { ...address, address_type: "contact" };
      const response = await addresses.createFromAccount(addressParameters);
      if (response) {
        commit("setFormErrors", [], { root: true });
        return response.data.id;
      }
      return false;
    } catch (error) {
      if (error.response) {
        commit("setLoading", false, { root: true });
        commit("setFormErrors", error.response.data, { root: true });
        throw new Error("Invalid address");
      }
      return false;
    }
  },
  async createPostcardAddress({ commit }, address) {
    commit("setLoading", true, { root: true });
    try {
      const addressParameters = { ...address, address_type: "contact" };
      const response = await addresses.createFromAccount(
        addressParameters,
        true
      );
      if (response) {
        commit("setFormErrors", [], { root: true });
        commit("setPostcardAddressId", response.data.id);
        commit("setLoading", false, { root: true });
      }
      return false;
    } catch (error) {
      if (error.response) {
        commit("setLoading", false, { root: true });
        commit("setFormErrors", error.response.data, { root: true });
        throw new Error("Invalid address");
      }
      return false;
    }
  },
  async updateItemAddress(
    { commit, dispatch },
    { address, itemId, contactAddressId }
  ) {
    commit("setLoading", true, { root: true });
    try {
      const response = await cartItems.updateAddress(
        itemId,
        address,
        contactAddressId
      );
      if (response) {
        commit("setFormErrors", [], { root: true });
        dispatch("cart/commitCartData", response.data, { root: true });
        commit("setLoading", false, { root: true });
      }
      return false;
    } catch (error) {
      if (error.response) {
        commit("setLoading", false, { root: true });
        commit("setFormErrors", error.response.data, { root: true });
        throw new Error("Invalid address");
      }
      return false;
    }
  },
  async updateItemDespatchDate({ commit, dispatch }, { date, itemId }) {
    commit("setLoading", true, { root: true });
    try {
      const response = await cartItems.updateDespatchDate(itemId, date);
      if (response) {
        commit("setFormErrors", [], { root: true });
        dispatch("cart/commitCartData", response.data, { root: true });
        commit("setLoading", false, { root: true });
      }
      return false;
    } catch (error) {
      if (error.response) {
        commit("setLoading", false, { root: true });
        commit("setFormErrors", error.response.data, { root: true });
        throw new Error("Invalid date");
      }
      return false;
    }
  }
};

const getters = {
  canShowReminderModal(state) {
    return state.remindable && !state.hasSeenReminderModal;
  }
};

const mutations = {
  setCardModalShowing(state, flag) {
    state.cardModalShowing = flag;
  },
  setSendToThemModalShowing(state, flag) {
    state.sendToThemModalShowing = flag;
  },
  setAskForDispatchDate(state, flag) {
    state.askForDispatchDate = flag;
  },
  setAskForAddress(state, flag) {
    state.askForAddress = flag;
  },
  setPostcardAddressId(state, id) {
    state.postcardAddressId = id;
  },
  setUpdatingFromCart(state, flag) {
    state.updatingFromCart = flag;
  },
  setIndirectSendAvailable(state, flag) {
    state.indirectSendAvailable = flag;
  },
  setContactAddresses(state, contactAddresses) {
    state.contactAddresses = contactAddresses;
  },
  addContactAddress(state, contactAddress) {
    state.contactAddresses.push(contactAddress);
  },
  setRemindable(state, flag) {
    state.remindable = flag;
  },
  setHasSeenReminderModal(state, flag) {
    state.hasSeenReminderModal = flag;
  },
  setReminderModalShowing(state, flag) {
    state.reminderModalShowing = flag;
  },
  setSendToThemData(state, data) {
    state.sendToThemData = data;
  },
  setQuickAddIds(state, quickAddIds) {
    state.quickAddIds = quickAddIds;
  }
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
};
