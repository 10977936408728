import api from "./index";

export default {
  async remove(item) {
    try {
      const response = await api.delete(`/basket/cart_items/${item.id}`);
      return response;
    } catch (error) {
      return false;
    }
  },
  async setQty(item, index, qty) {
    try {
      const response = await api.post(
        `/basket/cart_items/${item.id}/set_quantity.json`,
        { index, qty }
      );
      return response;
    } catch (error) {
      return false;
    }
  },
  async setEnvelopeQty(cartItemId, qty) {
    try {
      const response = await api.post(
        `/basket/cart_items/${cartItemId}/set_envelope_quantity.json`,
        { envelope_qty: qty }
      );
      return response;
    } catch (error) {
      return false;
    }
  },
  async setThickness(item, index, thick) {
    try {
      const response = await api.post(
        `/basket/cart_items/${item.id}/set_thickness.json`,
        { index, thick }
      );
      return response;
    } catch (error) {
      return false;
    }
  },
  async setShippingOptionId(itemId, id) {
    try {
      const response = await api.post(
        `/basket/cart_items/${itemId}/set_shipping_option_id.json`,
        { shipping_option_id: id }
      );
      return response;
    } catch (error) {
      return false;
    }
  },
  async removeShipmentItems(itemId) {
    try {
      const response = await api.delete(
        `/basket/cart_items/${itemId}/remove_shipment_items.json`
      );
      return response;
    } catch (error) {
      return false;
    }
  },
  async removeUpsells(item) {
    try {
      const response = await api.delete(
        `/basket/cart_items/${item.id}/remove_upsells`
      );
      return response;
    } catch (error) {
      return false;
    }
  },
  async addWeddingCreditUpsell(upsell) {
    try {
      const response = await api.post("/catalog/wedding_giftcards", {
        ...upsell,
        upsell_package: true
      });
      return response;
    } catch (error) {
      return false;
    }
  },
  async updateAddress(itemId, address, contactAddressId) {
    return api.post(`/basket/cart_items/${itemId}/set_new_address`, {
      address,
      contact_address_id: contactAddressId
    });
  },
  async updateDespatchDate(itemId, date) {
    try {
      const response = await api.put(
        `/basket/cart_items/${itemId}/update_despatch_date`,
        { date }
      );
      return response;
    } catch (error) {
      return false;
    }
  }
};
