import api from "./index";

window.lastPhotobookSaveTimestamp = null;

const allowSavePhotobook = parameters => {
  const now = Date.now();
  const allow =
    parameters.add_to_cart ||
    !window.lastPhotobookSaveTimestamp ||
    now - window.lastPhotobookSaveTimestamp > 5000;
  if (allow) {
    window.lastPhotobookSaveTimestamp = now;
  }

  return allow;
};

export default {
  async createOrUpdate(
    id,
    design,
    productId,
    justSave,
    postcardAddressId,
    templatedPreviewImage
  ) {
    try {
      if (id) {
        const response = await api.put(`/saved_designs/${id}`, {
          design,
          just_save: justSave,
          product_id: productId,
          postcard_address_id: postcardAddressId,
          templated_preview_image: templatedPreviewImage
        });
        return response;
      }
      const response = await api.post("/saved_designs/", {
        design,
        just_save: justSave,
        product_id: productId,
        postcard_address_id: postcardAddressId,
        templated_preview_image: templatedPreviewImage
      });
      return response;
    } catch (error) {
      return false;
    }
  },
  async addCardToCart(id, parameters) {
    try {
      return await api.post(`/saved_designs/${id}/add_to_cart`, parameters);
    } catch (error) {
      return false;
    }
  },
  async savePhotobook(id, parameters) {
    try {
      if (allowSavePhotobook(parameters)) {
        return await api.post(
          `/saved_designs/${id}/save_photobook`,
          parameters
        );
      }

      return false;
    } catch (error) {
      return false;
    }
  },
  async index(page) {
    try {
      return await api.get(`/account/api/saved_designs?page=${page}`);
    } catch (error) {
      return false;
    }
  },
  async duplicate(id) {
    try {
      return await api.post(`/account/api/saved_designs/${id}/duplicate`);
    } catch (error) {
      return false;
    }
  },
  async delete(id) {
    try {
      return await api.delete(`/account/api/saved_designs/${id}`);
    } catch (error) {
      return false;
    }
  },
  async updateName(id, name) {
    try {
      return await api.patch(`/account/api/saved_designs/${id}/update_name`, {
        name
      });
    } catch (error) {
      return false;
    }
  },
  async addSample(id) {
    try {
      return await api.post(`/saved_designs/${id}/add_sample`);
    } catch (error) {
      return false;
    }
  },
  async quickAddToCart(productId, design) {
    try {
      const response = await api.post(`/quick_add/${productId}`, {
        design
      });
      return response;
    } catch (error) {
      return false;
    }
  },
  async weddingAddOnReview(designId, quickAddIds = null) {
    try {
      let url;

      if (quickAddIds && quickAddIds.length > 0) {
        const queryString = quickAddIds
          .map(id => {
            return `quick_add_ids[]=${id}`;
          })
          .join("&");

        window.location = `${window.countryCode}/saved_designs/${designId}/review?${queryString}`;
      } else {
        url = `/saved_designs/${designId}/review.json`;
      }

      const response = await api.get(url);
      return response;
    } catch (error) {
      return false;
    }
  }
};
